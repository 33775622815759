import Vue from 'vue'
import VueRouter from 'vue-router'
import LayOut from '../views/LayOut.vue'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(() => {})
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayOut,
    redirect: '/home',
    name: 'main',
    children: []
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登陆' },
    lable: '登陆',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/databoard',
    name: 'databoard',
    meta: { title: '数据主页' },
    lable:  '数据主页',
    component: ()=>import('@/views/databoard/databoard.vue')
  },
  {
    path: '/userTV',
    name: 'userTV',
    meta: { title: '人员数据' },
    lable: '人员数据',
    component: ()=>import('@/views/userTV.vue')
  }


]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  // 在路由跳转之前开启
  nprogress.start()
  const isLogin = sessionStorage.token
  if (to.path !== '/login'
    && to.path !== '/userTV') {
    if (isLogin) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }

  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '入职后台管理系统'
  }
})

router.afterEach((to, from) => {
  // 路由跳转结束之后 进度条结束
  nprogress.done()
  if (to.path === '/login' && from.path === '/') {
    document.title = to.meta.title
  }
})
export default router
