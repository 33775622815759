<template>
  <div class="tag">
    <el-tag :effect="$route.path === '/home' ? 'dark' : 'plain'" style="margin-left: 10px" @click="backhome"
      >首页</el-tag
    >
    <el-tag
      closable
      :effect="$route.path === item.path ? 'dark' : 'plain'"
      v-for="(item, index) in tags"
      :key="item.path"
      @click="handleClick(item.path)"
      @close="handleClose(item, index)"
      >{{ item.name }}</el-tag
    >
  </div>
</template>

<script>
export default {
  name: 'TaG',
  data() {
    return {
      tasgs: []
    }
  },
  methods: {
    handleClick(path) {
      if (path !== this.$route.path) {
        this.$router.push(path)
      }
    },
    handleClose(item, index) {
      // 关闭标签
      if (this.tags.length > 0) {
        this.$store.commit('delTags', item)
        const length = this.tags.length
        if (length === 0) {
          if (this.$route.path === '/home') {

          } else {
            this.$router.push('/home')
          }
        } else {
          if (item.path === this.$route.path) {
            this.$router.push(this.tags[length - 1].path)
          }
        }
      }
    },
    backhome() {
      if (this.$route.path === '/home') {
        this.$router.go(0)
      } else {
        this.$router.replace('/home')
      }
    }
  },
  computed: {
    tags() {
      return this.$store.state.stateTagsList
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  .el-tag {
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
