<template>
  <div>
    <el-menu

      router
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#00152a"
      text-color="#fff"
      active-text-color="#409EFF"
    >
      <template v-for="item in Menu">
        <el-submenu :key="item.lable" v-if="item.children" :index="item.lable">
          <template slot="title">
            <span :class="item.meta.icon"></span>
            <span>{{ item.lable }}</span>
          </template>
          <el-menu-item
            v-for="value in item.children"
            :key="value.path"
            :index="value.path"
            @click="clickMenu(value.path, value.lable)"
          >
            <span :class="value.meta.icon"></span>
            {{ value.lable }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item :key="item.path" v-else :index="item.path" @click="clickMenu(item.path, item.lable)">
          <!-- <span class="iconfont icon-shouye"></span> -->
          <span :class="item.meta.icon"></span>
          <span slot="title">{{ item.lable }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'CommonAside',
  data() {
    return {
      Menu: JSON.parse(sessionStorage.getItem('menuList'))
    }
  },
  methods: {
    clickMenu(path, name) {
      this.$store.commit('mutationSelectTags', { path, name })

    }
  },
  computed: {
    // noChildren() {
    //   return this.Menu.filter(item => !item.children)
    // },
    // hasChildren() {
    //   return this.Menu.filter(item => item.children)
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu {
  border-right: none;
  .el-menu-item:hover {
    background-color: rgba(255,255,255,0.7) !important;
    color: #409EFF !important;
  }
  // 设置选中时的背景颜色
  .el-menu-item.is-active {
    background-color: #ececec !important;
  }
  // 设置submenu鼠标滑过时的背景颜色
  .el-submenu__title:hover {
    background-color: rgba(255,255,255,0.7) !important;
    color: #409EFF !important;
  }
  // 字体加粗
  .el-menu-item {
    //font-weight: 700;
  }
  .el-submenu__title {
    //font-weight: 700;
  }
  // 设置submenu的图标
  .el-menu-item {
    //font-weight: 700;
  }
  .el-submenu__title {
    //font-weight: 700;
  }

  .el-menu-item.is-active {
    //font-weight: 700;
  }
  .el-submenu__title.is-active {
    //font-weight: 700;
  }
  // 设置submenu选中时的背景颜色
  .el-submenu__title.is-active {
    background-color: #ececec !important;
  }
  // 设置submenu的图标
  .iconfont {
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
  }
}
</style>
