<template>
  <div style="height: 100%">
    <div class="heade">
      <div class="breadcrumb">
        <BreadCrumb />
      </div>
      <div class="Icon">
        <el-tooltip class="item" effect="dark" :content="content" placement="bottom">
          <i class="el-icon-full-screen" @click="SetFullScreen"></i>
        </el-tooltip>
        <el-dropdown @command="ExitLogin">
          <el-avatar :size="50" :src="testImg"></el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>个人中心</el-dropdown-item>
            <el-dropdown-item command="loginout">退出登陆</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Tag />
  </div>
</template>

<script>
import screenfull from 'screenfull'
import testImg from '../assets/active.jpg'
import Tag from './components/Tag.vue'
import BreadCrumb from './components/Breadcrumb.vue'
export default {
  data() {
    return {
      testImg: testImg,
      content: '全屏'
    }
  },
  methods: {
    ExitLogin(action) {
      if (action === 'loginout') {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('stateTagsList')
        this.$store.commit('removestateTagsList')
        sessionStorage.removeItem('menuList')
        sessionStorage.removeItem('routes')
        this.$router.replace('/login')
      }
    },
    acitiveCollapse() {
      this.$store.commit('activeCollapse')
    },
    SetFullScreen() {
      if (!screenfull.isEnabled) {
        return false
      }
      if (screenfull.isFullscreen) {
        this.content = '全屏'
      } else {
        this.content = '退出全屏'
      }
      screenfull.toggle()
    }
  },
  components: {
    BreadCrumb,
    Tag
  }
}
</script>

<style lang="scss" scoped>
.heade {
  display: flex;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  .breadcrumb {
    display: flex;
    align-items: center;
    width: 50%;
  }
  .Icon {
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  img {
    width: 50px;
    height: 50px;
  }
}
</style>

