<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.meta.title !== '首页'">{{ $route.meta.title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  mounted() {
    console.log(this.$route)
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb__item {
  ::v-deep .el-breadcrumb__inner {
    &.is-link {
      font-weight: 700;
      color: #000000;
    }
  }
  ::v-deep .el-breadcrumb__inner:hover {
    &.is-link {
      font-weight: 700;
      color: #000000;
    }
  }
}
</style>
