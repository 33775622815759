<template>
  <div>
    <el-container>
      <el-aside width="240px">
        <div class="logo">
          <p style="
              width: 100%;
              height: 60px;
              color: #409EFF;
              font-size: 18px;
              font-weight: bold;
              line-height: 60px;
              text-align: center;
              background-color: #00152a;
              ">安徽立讯工具管理平台</p>
        </div>
        <CommonAside />
      </el-aside>
      <el-container>
        <el-header>
          <CommonHeader />
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonHeader from './CommonHeader.vue'
import CommonAside from './CommonAside.vue'
export default {
  name: 'LayOut',
  data() {
    return {
      msg: 1
    }
  },
  components: {
    CommonAside,
    CommonHeader
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  height: 100vh;
  .el-aside {
    background-color: #00152a;
    overflow: auto;
    scrollbar-width: none;
    scrollbar-height: none;
  }
  .el-main {
    background-color: #f5f5f5;
    padding: 10px 20px;
  }
  .el-header {
    background: #fff;
    padding: 0;
    height: 110px !important;
  }
}
.logo{
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
