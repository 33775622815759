import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    stateTagsList: JSON.parse(sessionStorage.getItem('stateTagsList')) || [],
    menuList: JSON.parse(sessionStorage.getItem('menuList')) || [],
    routes: JSON.parse(sessionStorage.getItem('routes')) || []
  },
  getters: {},
  mutations: {
    // 点击侧边栏添加标签
    mutationSelectTags(state, value) {
      if (state.stateTagsList.length === 0 && value.path !== '/home') {
        state.stateTagsList.push(value)
      } else {
        if (!state.stateTagsList.some(item => item.path === value.path) && value.path !== '/home') {
          state.stateTagsList.push(value)
        }
      }
      sessionStorage.setItem('stateTagsList', JSON.stringify(state.stateTagsList))
    },
    // 关闭标签
    delTags(state, value) {
      const msg = state.stateTagsList.findIndex(item => item.path === value.path)
      state.stateTagsList.splice(msg, 1)
      sessionStorage.setItem('stateTagsList', JSON.stringify(state.stateTagsList))
    },
    // 创建菜单列表
    setmenuList(state, value) {
      state.menuList = value
    },
    setRoutes(state, router) {
      // 动态添加路由
      state.routes = JSON.parse(sessionStorage.getItem('routes')) || []
      if (state.routes.length !== 0) {
        const routes = state.routes.map(item => {
          return {
            path: item.path,
            name: item.name,
            lable: item.lable,
            component: () => import(`../views/pages/${item.name}`),
            meta: {
              title: item.meta.title
            }
          }
        })
        console.log(routes)
        routes.forEach(item => {
          router.addRoute('main', item)
        })
      }
    },
    // 清空tags
    removestateTagsList(state, value) {
      state.stateTagsList = []
    }
  },
  actions: {},
  modules: {}
})
