import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'  //引入element-ui库
import VForm from 'vform-builds'  //引入VForm库
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/icon/iconfont.css'
import 'vform-builds/dist/VFormDesigner.css'  //引入VForm样式
import '@/assets/config.css'
//全局引入echarts
import * as echarts from 'echarts';
import dataV from '@jiaminghi/data-view'
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts;


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VForm)  //全局注册VForm(同时注册了v-form-designer和v-form-render组件)
Vue.use(dataV)

new Vue({
  router,
  store,
  created() {
    store.commit('setRoutes', router)
  },
  render: h => h(App)
}).$mount('#app')
